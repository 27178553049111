// import allRouter from '@/router/modules.js'
// import store from '@/store'


// import { getRole } from '@/api/auth.js'


export function filterRouter (resRouter, allRouters) {
    return allRouters.map(item => {
        const matchedRouter = resRouter.find(all => item.path === all.url);
        if (matchedRouter) {
            const newItem = { ...item };
            newItem.meta.hideNav = true;
            newItem.meta.title = matchedRouter.name;
            newItem.meta.LoginRoad = matchedRouter.name;
            if (matchedRouter.checkedStatus === 2) {
                matchedRouter.hasChecked = true;
            }
            newItem.meta.hideNav = !matchedRouter.hasChecked;
            newItem.children = newItem.children && newItem.children.length > 0 ? filterRouter(matchedRouter.childrenAuthUrl, newItem.children) : undefined;
            return newItem;
        }
        return null;
    }).filter(Boolean);
}
