import axios from 'axios'
import store from '@/store'
import { Message } from 'element-ui'
import router from '@/router'
import { getToken, setToken, removeToken, getLoginRoad, removeLoginRoad } from './token.js'
import Vue from "vue";

// // 配置默认基本路径
// axios.defaults.baseURL = 'http://192.168.6.225:29011/'
// axios.defaults.timeout = 5000;

let baseUrl = window.server.SERVER_API;

// 创建一个新的axios
const request = axios.create({
    baseURL: baseUrl,
    // timeout: 10000,
})
const noTimeOutReq = axios.create({
    baseURL: baseUrl,
})

// axios请求拦截
request.interceptors.request.use(
    (config) => {
        // config 请求配置
        const token = getToken()

        token && (config.headers['RPAuthorization'] = token)
        // 请求成功拦截
        // console.log("请求拦截器")
        return config
    },
    (err) => {
        // 请求失败拦截
        return Promise.reject(err)
    }
)

let loginStatus = true;


// axios响应拦截
request.interceptors.response.use(
    (res) => {
        console.log('loginStatus', loginStatus);
        if ((res.data.code == 403 || res.data.code == 414) && loginStatus) {
            Message.warning('登录过期，需重新登录')
            store.commit('EMPTY_NAV')
            removeToken()
            loginStatus = false
            setTimeout(() => {
                if (!getLoginRoad()) {
                    router.replace('/selectLogin')
                } else {
                    // removeLoginRoad()
                    router.replace('/cityPlatformLogin')
                }
            }, 1000)
        }
        if (!loginStatus) {
            setTimeout(() => {
                loginStatus = true
            }, 1500);
        }
        if (res.data.code == 500) {
            ; (res.data.msg) && Message.warning('code:500,msg:' + res.data.msg)
                ; (res.msg) && Message.warning('code:500,msg:' + res.msg)
        }
        // if (!res.data.success) {
        //     Message.warning(res.data.msg);
        // }
        ; (!!res.headers.renewrpauthorization) && setToken(res.headers.renewrpauthorization)
        return res.data
    },
    (err) => {
        if (err.code == 'ECONNABORTED') Message.error('请求超时')
        // 响应拦失败拦截
        return Promise.reject(err)
    }
)
// axios请求拦截
noTimeOutReq.interceptors.request.use(
    (config) => {
        // config 请求配置
        const token = getToken()

        token && (config.headers['RPAuthorization'] = token)
        // 请求成功拦截
        // console.log("请求拦截器")
        return config
    },
    (err) => {
        // 请求失败拦截
        return Promise.reject(err)
    }
)

// axios响应拦截
noTimeOutReq.interceptors.response.use(
    (res) => {
        console.log('loginStatus', loginStatus);
        if ((res.data.code == 403 || res.data.code == 414) && loginStatus) {
            Message.warning('登录过期，需重新登录')
            store.commit('EMPTY_NAV')
            loginStatus = false
            removeToken()
            setTimeout(() => {
                if (!getLoginRoad()) {
                    router.replace('/selectLogin')
                } else {
                    // removeLoginRoad()
                    router.replace('/cityPlatformLogin')
                }
            }, 1000)
        }
        if (!loginStatus) {
            setTimeout(() => {
                loginStatus = true
            }, 1500);
        }
        if (res.data.code == 500) {
            ; (res.data.msg) && Message.warning('code:500,msg:' + res.data.msg)
                ; (res.msg) && Message.warning('code:500,msg:' + res.msg)

        }
        ; (!!res.headers.renewrpauthorization) && setToken(res.headers.renewrpauthorization)
        // if (!res.data.success) {
        //     Message.warning(res.data.msg);
        // }
        return res.data
    },
    (err) => {
        // 响应拦失败拦截
        return Promise.reject(err)
    }
)

export { request, noTimeOutReq }