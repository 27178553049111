// 档案管理模块
import LayoutMain from '@/layout/LayoutMain'

const archive = {
    path:'/archive',
    component: LayoutMain,
    redirect:'/archive/archiveManagement',
    meta: {
        title:'档案管理',
        LoginRoad:'档案数据管理',
        icon:'el-icon-document'
    },
    children:[
        {
            path:'/archive/archiveManagement',
            name:'archiveManagement',
            meta: {
                title: '考试数据归档',
                loginRoad:'考试数据归档',
            },
            component: () => import('@/views/archive/archiveManagement/archiveManagement.vue')
        },
        {
            path:'/archive/archiveDetail',
            name:'archiveDetail',
            meta: {
                title: '考试数据归档详情',
                loginRoad:'考试数据归档详情',
                hideNav: true
            },
            component: () => import('@/views/archive/archiveManagement/archiveDetail.vue')
        },
        {
            path:'/archive/examineeVideo',
            name:'examineeVideo',
            meta: {
                title: '考生视频',
                loginRoad:'考生视频',
                hideNav: true
            },
            component: () => import('@/views/archive/archiveManagement/examineeVideo.vue')
        },
        // {
        //     path:'/archive/examineeArchiveManagement',
        //     name:'examineeArchiveManagement',
        //     meta: {
        //         title: '考生数据归档',
        //         loginRoad:'考生数据归档',
        //     },
        //     component: () => import('@/views/archive/examineeArchiveManagement/examineeArchiveManagement.vue')
        // },
        // {
        //     path:'/archive/examineeArchiveManagementDetail',
        //     name:'examineeArchiveManagementDetail',
        //     meta: {
        //         title: '考生数据归档详情',
        //         loginRoad:'考生数据归档详情',
        //         hideNav: true
        //     },
        //     component: () => import('@/views/archive/examineeArchiveManagement/examineeArchiveManagementDetail.vue')
        // },
    ]
}

export default archive