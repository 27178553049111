import { getStore } from '@/utils/token.js'


export function imageAddress (url) {
  let imgUrl = getStore('LOCAL_OSS');
  if (window.server.SERVER_OSS_CONF_type) {
    imgUrl = window.server.SERVER_OSS
  }
  // console.log('返回的路径', imgUrl + url);
  // alert(imgUrl)
  return `${imgUrl}${url}`
}

export function apiAddress (url) {
  let apiUrl = window.server.SERVER_API;
  // console.log('返回的路径', apiUrl + url);
  return `${apiUrl}${url}`
}

export function mediaAddress (url) {
  let mediaUrl = getStore('LOCAL_MEDIA');
  // console.log('返回的路径', mediaUrl + url);
  return `${mediaUrl}${url}`
}

export function mediaHttpAddress (url) {
  let mediaHttpUrl = getStore('LOCAL_MEDIA_HTTP');
  if (window.server.SERVER_MEDIA_HTTP_CONF_TYPE) {
    mediaHttpUrl = window.server.SERVER_MEDIA_HTTP
  }
  // console.log('返回的路径', mediaUrl + url);
  return `${mediaHttpUrl}${url}`
}

// 倒计时
export function countdown (start, end) {
  let res = Math.max(0, end - start) / 1000; // 剩余时间总的秒数，确保时间不会小于0
  const d = Math.floor(res / 86400); // 天
  const h = Math.floor(res % 86400 / 3600); // 时
  const m = Math.floor(res % 3600 / 60); // 分
  const s = Math.floor(res % 60); // 秒
  let timeString = '';
  if (d > 0) {
    timeString += `${String(d).padStart(2, '0')}:`;
  }
  return `${timeString}${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
}
