<template>
  <div class="clearfix">
    <div class="lf headerBtn" @click="CHANGE_COLLAPSE">
      <i :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
    </div>
    <!-- 面包屑 -->
    <div class="lf headerBreadCrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="item in breadcrumbList" :key="item.title">
          <router-link :to="{ path: item.path }" v-if="item.path">{{item.title}}</router-link>
          <span v-else>{{ item.title }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 头像 -->
    <div class="rf">
      <el-dropdown @command="handleCommand" :hide-on-click="false">
        <div class="el-dropdown-link" style="display:flex">
          <div class="headerAvatar">
            <img src v-if="0" />
            <i v-else class="el-icon-user-solid" style="font-size:20px"></i>
          </div>
          <span>
            {{temporaryName}}
            <i class="el-icon-caret-bottom"></i>
          </span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="3">账户信息</el-dropdown-item>
          <el-dropdown-item :command="2">修改密码</el-dropdown-item>
          <el-dropdown-item :command="1">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { quit } from "@/api/auth.js";
import { removeToken, getName, removeName, getLoginRoad, removeLoginRoad } from '@/utils/token.js'
import { removeStore } from '@/utils/token.js'
export default {
  name: "LayoutHeader",
  data () {
    return {
      breadcrumbList: [{ path: "/home", title: "首页" }],
      temporaryName: "admin",
      loginCity: false
    };
  },
  created () {
    this.routeData();
    this.temporaryName = getName()
    this.loginCity = !!getLoginRoad()
  },
  watch: {
    $route () {
      this.routeData();
    },
  },
  computed: {
    ...mapGetters(["isCollapse"]),
  },
  methods: {
    ...mapActions(["CHANGE_COLLAPSE"]),
    routeData () {
      console.log(this.$route);
      this.breadcrumbList = [{ path: "/home", title: "首页" }];
      this.$route.matched.filter((item) => {
        let breadContent = {};
        if (item.meta.title && item.meta.title != "首页") {
          this.breadcrumbList[0].path = "/home";
          if (this.loginCity) {
            breadContent.title = (item.meta.LoginRoad) ? item.meta.LoginRoad : item.meta.title;

          } else {
            breadContent.title = item.meta.title;
          }

          !item.redirect && (breadContent.path = item.path);
          this.breadcrumbList.push(breadContent);
        } else {
          this.breadcrumbList = [{ title: "首页" }];
        }
      });
    },
    // 头像框所有事件处理
    handleCommand (key) {
      switch (key) {
        case 1:
          this.quitBtn()
          break;
        case 2:
          this.$router.push({
            path: '/account/revisePassword',
          })
          break;
        case 3:
          this.$router.push({
            path: '/account/accountInfo',
          })
          break;

        default:
          console.log('错误key')
          break;
      }
    },
    // 退出登录
    quitBtn () {
      this.$confirm('确认退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        quit().then(res => {
          if (res.success) {
            removeStore('LOCAL_OSS');
            removeStore('LOCAL_MEDIA');
            removeToken()
            removeName()
            setTimeout(() => {
              if (!getLoginRoad()) {
                // removeLoginRoad()
                this.$router.replace('/selectLogin')
              } else {
                // removeLoginRoad()
                this.$router.replace('/cityPlatformLogin')
              }

            }, 500)
          }
        })
      }).catch(() => {
        this.$message({ type: 'info', message: '已取消' });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .headerBtn {
  margin-left: -20px;
  padding: 0 20px;
  height: 56px;
  line-height: 56px;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  &:hover {
    background: #f0f2f5;
  }
  i {
    font-size: 24px;
    line-height: 56px;
  }
}
::v-deep .headerBreadCrumb {
  height: 56px;
  line-height: 56px;
  .el-breadcrumb {
    height: 56px;
    line-height: 56px;
  }
}
.el-dropdown-link {
  cursor: pointer;
}
.headerAvatar {
  display: block;
  margin: 8px 0;
  width: 40px;
  height: 40px;
  background: #409eff;
  border-radius: 8px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    // border-radius: 8px;
    width: 100%;
    height: 100%;
  }
}
</style>
