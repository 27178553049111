<template>
  <div>
    <!-- v-if="loginCity" -->
    <template v-if="loginCity">
      <!-- <div v-if="isCollapse" class="asideTitLogo">
        <img src="../assets/logo.png" alt />
      </div>
      <div v-else class="asideTit asideTit2">
        <img src="../assets/logo.png" alt />
        {{platformName}}
      </div>-->
      <div v-if="isCollapse" class="asideTitLogo">
        <img src="../assets/logo.png" alt />
      </div>
      <div v-else class="asideTit asideTit2">{{ platformName }}</div>
    </template>
    <template v-else>
      <div v-if="isCollapse" class="asideTitLogo">
        <img src="../assets/logo.png" alt />
      </div>
      <div v-else class="asideTit">{{ platformName }}</div>
    </template>

    <el-menu
      router
      :default-active="$route.path"
      :class="loginCity?'loginCity el-menu-vertical-demo':'el-menu-vertical-demo'"
      :collapse="isCollapse"
      :background-color="loginCity?'#00162B':'rgb(48, 65, 86)'"
      text-color="#fff"
      active-text-color="#409EFF"
    >
      <nav-menu :navList="leftNav"></nav-menu>
    </el-menu>
  </div>
</template>
<script>
import navMenu from './components/navMenu.vue'
import { getLoginRoad, getStore } from '@/utils/token.js'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'LayoutAside',
  data() {
    return {
      loginCity: false,
      // platformName: ''
    }
  },
  components: {
    navMenu,
  },
  computed: {
    ...mapGetters(['isCollapse', 'leftNav', 'platformName']),
  },
  methods: {},
  created() {
    this.loginCity = !!getLoginRoad()
    // this.platformName = getStore('PLAT_FOR_NAME')
    this.$store.dispatch('GET_PlatformName')
  },
}
</script>
<style lang="scss" scoped>
::v-deep .asideTit {
  height: 56px;
  font-size: 18px;
  line-height: 56px;
  background-color: rgb(48, 65, 86);
  color: #fff;
  font-weight: 550;
  width: 224px;
  transition: all 3.6s;
}
::v-deep .asideTit2 {
  color: #1890ff;
  background-color: #00162b;
  // font-weight: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 30px;
  }
  transition: all 0s;
}
::v-deep .el-menu {
  border-right: none;
  text-align: left;
}
::v-deep .asideTitLogo {
  height: 56px;
  width: 64px;
  padding: 0 12px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  box-sizing: border-box;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
::v-deep .el-submenu__title {
  font-size: 16px;
}
::v-deep .el-menu-item {
  font-size: 16px;
}
::v-deep .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 224px;
}
::v-deep .el-submenu__icon-arrow {
  margin-top: -6px;
  font-size: 16px;
}
::v-deep .el-menu--collapse .el-submenu__title {
  // transition: all 2s;
}
/*隐藏文字*/
::v-deep .el-menu--collapse .el-submenu__title span {
  // display: none;
  visibility: hidden;
  transition: all 2s;
}
/*隐藏 > */
::v-deep .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  // display: none;
  visibility: hidden;
  transition: all 2s;
}
</style>
<style>
/* 改变elementui 侧边栏移入颜色 */
.loginCity .el-menu-item:hover {
  background-image: linear-gradient(to right, #1890ff, #68b5ff) !important;
  color: #fff !important;
}
.loginCity .el-submenu__title:hover {
  background-image: linear-gradient(to right, #1890ff, #68b5ff) !important;
  color: #fff !important;
}
.loginCity .el-menu-item.is-active {
  background-image: linear-gradient(to right, #1890ff, #68b5ff) !important;
  color: #fff !important;
}
.loginCity .el-submenu__title.is-active {
  background-image: linear-gradient(to right, #1890ff, #68b5ff) !important;
  color: #fff !important;
}
</style>