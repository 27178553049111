const draw = [
  {
    path: '/draw/report',
    name: 'report',
    meta: {
      title: '抽签',
      // keepAlive: true,
      hideNav: true
    },
    component: () => import('@/views/draw/report.vue')
  },
  {
    path: '/draw/draw',
    name: 'draw',
    meta: {
      title: '抽签',
      // keepAlive: true,
      hideNav: true
    },
    component: () => import('@/views/draw/draw.vue')
  },
]

export default draw