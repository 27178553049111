// 考试模块
import LayoutMain from '@/layout/LayoutMain'

const exam = {
    path: '/exam',
    component: LayoutMain,
    redirect: '/exam/examManage',
    meta: {
        title: '考务管理',
        LoginRoad: '市级考务管理',
        icon: 'el-icon-s-marketing'
    },
    children: [
        {
            path: '/exam/examManage',
            name: 'examManage',
            meta: {
                title: '考试安排',
                LoginRoad: '考试安排',
            },
            component: () => import('@/views/exam/examManage/examManage.vue'),
        },
        {
            path: '/exam/examVideoCheck',
            name: 'examVideoCheck',
            meta: {
                title: '考试巡查',
                LoginRoad: '考试巡查',
                // hideNav: true
            },
            component: () => import('@/views/exam/examManage/examVideoCheck.vue'),
        },
        {
            path: '/exam/examAdd',
            name: 'examAdd',
            meta: {
                title: '添加考试',
                hideNav: true
            },
            component: () => import('@/views/exam/examAdd/examAdd.vue'),
        },
        {
            path: '/exam/examCheck',
            name: 'examCheck',
            meta: {
                title: '查看考试',
                hideNav: true
            },
            component: () => import('@/views/exam/examAdd/examCheck.vue'),
        },
        {
            path: '/exam/examRetake',
            name: 'examRetake',
            meta: {
                title: '补考名单',
                hideNav: true
            },
            component: () => import('@/views/exam/examAdd/examRetake.vue'),
        },
    ]
}

export default exam