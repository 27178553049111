import { request, noTimeOutReq } from '@/utils/request.js'

//获取磁盘信息
export function getServerDiskApi () {
  return request({
    url: `/server/web/disk/status/get`,
    method: 'post',
  })
}

//获取安装包列表
export function getServerPackageListApi () {
  return request({
    url: `/server/package/list`,
    method: 'post',
  })
}

//根据类型获取安装包列表类型
export function getServerPackageTypeApi (type) {
  return request({
    url: `/server/package/list/by/${type}`,
    method: 'post',
  })
}


//上传安装包
export function uploadServerPackageApi (data, type) {
  return noTimeOutReq({
    url: `/server/package/upload`,
    method: 'post',
    data
  })
}


/**
 * 终端配置
 */

// 获取【AI算力盒】配置
export function getAICalculationTerminalConfig () {
  return request({
    url: `/terminal/web/config/ai/calculation/terminal/get`,
    method: 'post',
  })
}
// 恢复【AI算力盒】默认配置
export function resetAICalculationTerminalConfig () {
  return request({
    url: `/terminal/web/config/ai/calculation/terminal/reset`,
    method: 'post',
  })
}
// 更新【AI算力盒】配置
export function updateAICalculationTerminalConfig (data) {
  return request({
    url: `/terminal/web/config/ai/calculation/terminal/update`,
    method: 'post',
    data
  })
}


// 获取【阅卷客户端】配置
export function getMarkTerminalConfig () {
  return request({
    url: `/terminal/web/config/mark/terminal/get`,
    method: 'post',
  })
}
// 恢复【阅卷客户端】默认配置
export function resetMarkTerminalConfig () {
  return request({
    url: `/terminal/web/config/mark/terminal/reset`,
    method: 'post',
  })
}
// 获取【阅卷客户端】配置
export function updateMarkTerminalConfig (data) {
  return request({
    url: `/terminal/web/config/mark/terminal/update`,
    method: 'post',
    data
  })
}


// 获取【平台前端】配置
export function getPlatformFrontTerminalConfig () {
  return request({
    url: `/terminal/web/config/platform/front/terminal/get`,
    method: 'post',
  })
}
// 恢复【平台前端】默认配置
export function resetPlatformFrontTerminalConfig () {
  return request({
    url: `/terminal/web/config/platform/front/terminal/reset`,
    method: 'post',
  })
}
// 更新【平台前端】配置
export function updatePlatformFrontTerminalConfig (data) {
  return request({
    url: `/terminal/web/config/platform/front/terminal/update`,
    method: 'post',
    data
  })
}


// 获取【学生端】配置
export function getStudentTerminalConfig () {
  return request({
    url: `/terminal/web/config/student/terminal/get`,
    method: 'post',
  })
}
// 恢复【学生端】默认配置
export function resetStudentTerminalConfig () {
  return request({
    url: `/terminal/web/config/student/terminal/reset`,
    method: 'post',
  })
}
// 更新【学生端】配置
export function updateStudentTerminalConfig (data) {
  return request({
    url: `/terminal/web/config/student/terminal/update`,
    method: 'post',
    data
  })
}


// 获取【监考端】配置
export function getTeacherTerminalConfig () {
  return request({
    url: `/terminal/web/config/teacher/terminal/get`,
    method: 'post',
  })
}
// 恢复【监考端】默认配置
export function resetTeacherTerminalConfig () {
  return request({
    url: `/terminal/web/config/teacher/terminal/reset`,
    method: 'post',
  })
}
// 更新【监考端】配置
export function updateTeacherTerminalConfig (data) {
  return request({
    url: `/terminal/web/config/teacher/terminal/update`,
    method: 'post',
    data
  })
}
