// 评分端

const PadRating = [
  {
    path: '/chooseExamRoom',
    name: 'chooseExamRoom',
    meta: {
      title: '选择考场',
      hideNav: true
    },
    component: () => import('@/views/PadRating/chooseExamRoom.vue')
  },
  {
    path: '/scoreSelection',
    name: 'scoreSelection',
    meta: {
      title: '选择座位',
      hideNav: true
    },
    component: () => import('@/views/PadRating/scoreSelection.vue')
  },
  {
    path: '/siteScoring',
    name: 'siteScoring',
    meta: {
      title: '评分',
      hideNav: true
    },
    component: () => import('@/views/PadRating/siteScoring.vue')
  },
]

export default PadRating
