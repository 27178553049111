import router from '@/router/index.js'

const tagsView = {
  state: {
    //tags数组
    tags: [
      {
        path: "/home",
        title: "首页"
      }
    ],
    //tagsView标签显示隐藏
    isShow: false
  },
  mutations: {
    pushTags (state, val) {
      //如果等于-1说明tabs不存在那么插入，否则什么都不做
      //findIndex找角标，循环判断一下，如果等于那么就代表有相同的，就不必添加，如果找不到那就是-1.就添加
      let result = state.tags.findIndex(item => item.title === val.title)
      result == -1 ? state.tags.push(val) : ''
    },
    //关闭标签
    closeTab (state, val) {
      //同上，找角标，然后用角标的位置对应删除一位。splice：这是数组的删除方法
      let result = state.tags.findIndex(item => item.title === val.title)
      state.tags.splice(result, 1)
    },
    //关闭所有tagsView标签
    clearTagsView (state, val) {
      //清空数组
      state.tags = [{ path: "/home", title: "首页" }]
      //跳转到首页,val接受传过来的当前路由
      if (val !== "/") {
        router.replace({ path: "/" })
      }
    },
    //改变tagsView显示隐藏
    changeIsShow (state) {
      state.isShow = !state.isShow
    },
    // 清除其他
    clearOtherTagsView (state, val) {
      state.tags = [{ path: "/home", title: "首页" }]
      state.tags.push(val)
      router.replace({ path: val.path })
    }
  },
  actions: {

  }
}

export default tagsView 