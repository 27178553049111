const markingVideo = [
  {
    path: '/markingVideo/markingVideo',
    name: 'markingVideo',
    meta: {
      title: '视频评卷',
      hideNav: true
    },
    component: () => import('@/views/markingVideo/markingVideo.vue'),
  },
  {
    path: '/markingVideo/markingVideoDissent',
    name: 'markingVideoDissent',
    meta: {
      title: '查看|异议',
      hideNav: true
    },
    component: () => import('@/views/markingVideo/markingVideoDissent.vue'),
  },
  {
    path: '/markingVideo/markingVideoFormal',
    name: 'markingVideoFormal',
    meta: {
      title: '评卷',
      hideNav: true
    },
    component: () => import('@/views/markingVideo/markingVideoFormal.vue'),
  },
  {
    path: '/markingVideo/markingVideoReview',
    name: 'markingVideoReview',
    meta: {
      title: '回评|查看',
      hideNav: true
    },
    component: () => import('@/views/markingVideo/markingVideoReview.vue'),
  },
  {
    path: '/markingVideo/markingVideoArbitrate',
    name: 'markingVideoArbitrate',
    meta: {
      title: '仲裁',
      hideNav: true
    },
    component: () => import('@/views/markingVideo/markingVideoArbitrate.vue'),
  },
]
export default markingVideo
