// 用户模块
// import LayoutMain from '@/layout/LayoutMain'

const rollCall = [
  {
    path: '/rollCall/report',
    name: 'report',
    meta: {
      title: '报到页',
      hideNav: true
    },
    component: () => import('@/views/rollCall/report.vue')
  },
  {
    path: '/rollCall/centralizeRollCall',
    name: 'centralizeRollCall',
    meta: {
      title: '集中点名',
      hideNav: true
    },
    component: () => import('@/views/rollCall/components/centralizeRollCall.vue'),
  },
  {
    path: '/rollCall/separateRollCall',
    name: 'separateRollCall',
    meta: {
      title: '分开点名',
      hideNav: true
    },
    component: () => import('@/views/rollCall/components/separateRollCall.vue'),
  },
  {
    path: '/rollCall/batchView',
    name: 'batchView',
    meta: {
      title: '考生一览',
      hideNav: true
    },
    component: () => import('@/views/rollCall/components/batchView.vue'),
  },
  {
    path: '/rollCall/historyBatch',
    name: 'historyBatch',
    meta: {
      title: '历史批次',
      hideNav: true
    },
    component: () => import('@/views/rollCall/components/historyBatch.vue'),
  },
]

export default rollCall
