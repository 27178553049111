<!-- 定义全局变量 -->
<script>
const globalApiUrl = 'http://192.168.6.226:9011';
const globalPicPrefixUrl = 'http://192.168.6.226:9000';
const graduationYearList = [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
//科目
const globalSubjectOptions = [
  { label: '物理', value: 1 },
  { label: '生物', value: 2 },
  { label: '化学', value: 3 },
];
//考试状态
const globalExamStatusOptions = [
  // { label: '草稿', value: 1 },
  { label: '等待考试', value: 2 },
  { label: '正在考试', value: 3 },
  { label: '考试完成', value: 4 },
  /*  { label: '等待补考', value: 5 },
   { label: '正在补考', value: 6 },
   { label: '考试终止', value: 7 }, */
];
//考试数据
const globalExamDataStatusOptions = [
  { label: '未获取', value: 1 },
  { label: '已获取', value: 2 },
  { label: '已确认', value: 3 },
];
//阅卷结果状态
const globalMarkResultStatusOptions = [
  { label: '未上传', value: 1 },
  { label: '已上传', value: 2 },
];
//性别
const globalGenderOptions = [
  { label: '女', value: 0 },
  { label: '男', value: 1 },
];

export default {
  //globalApiUrl, // 后台请求路径
  //globalPicPrefixUrl, // 回显图片统一路径
  graduationYearList,
  globalSubjectOptions,
  globalExamStatusOptions,
  globalExamDataStatusOptions,
  globalMarkResultStatusOptions,
  globalGenderOptions
}
</script>
