
export default {
  inserted (el, binding) {
    el.addEventListener('click', e => {
      let control = binding.value
      if (!!control) return
      // if (!time) time = 1200
      el.classList.add('is-disabled');
      el.disabled = true;
      setTimeout(() => {
        el.disabled = false;
        el.classList.remove('is-disabled');
      }, 1200)
    })
  }
}
