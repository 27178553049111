const markingTest = {
  state: {
    markTestExamInfo: {},
    operationTestId: '',
  },
  mutations: {
    setMarkExamInfo (state, examInfo) {
      state.markTestExamInfo = examInfo;
    },
    setOperationTestId (state, operationTestId) {
      state.operationTestId = operationTestId;
    }
  }
}
export default markingTest