import Vue from 'vue'
import Vuex from 'vuex'
import nav_data from './modules/nav_data.js'
import tagsView from './modules/tagsView.js'
import markingTest from './modules/markingTest.js'
import examResourceOccupation from './modules/examResourceOccupation.js'

Vue.use(Vuex)

export default new Vuex.Store({
  getters: {
    isCollapse: state => state.nav_data.isCollapse,
    platformName: state => state.nav_data.platformName,
    nav: state => state.nav_data.nav,
    leftNav: state => state.nav_data.leftNav
  },
  modules: {
    nav_data,
    tagsView,
    markingTest,
    examResourceOccupation
  }
})
