//用户信息
import LayoutMain from '@/layout/LayoutMain'

const account = {
  path: '/account',
  component: LayoutMain,
  redirect: '/account/accountInfo',
  meta: {
    title: '',
    icon: 'el-icon-s-tools',
    hideNav: true
  },
  children: [{
      path: '/account/accountInfo',
      name: 'accountInfo',
      meta: {
        title: '用户信息',
        hideNav: true
        // keepAlive: true,
      },
      component: () => import('@/views/account/accountInfo.vue'),
    },
    {
      path: '/account/revisePassword',
      name: 'revisePassword',
      meta: {
        title: '修改密码',
        hideNav: true
      },
      component: () => import('@/views/account/revisePassword.vue'),
    }
  ]
}

export default account