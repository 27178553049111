
export default {
  inserted (el, binding) {
    const element = el.getElementsByTagName('input')[0]

    element.addEventListener('change', () => {
      console.log(element.value);
      element.value = element.value.replace(/[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5_]/g, '')
    })
  }
}
