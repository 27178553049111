// 考试大数据
import LayoutMain from '@/layout/LayoutMain'

const examBigData = {
    path: '/data',
    component: LayoutMain,
    redirect: '/data/examBigData',
    meta: {
        title: '考试大数据',
        icon: 'el-icon-s-data',
        // 临时
        hideNav: true,
    },
    children: [
        {
            path: '/data/examBigData',
            name: 'examBigData',
            meta: {
                title: '考试大数据',
                // 临时
                hideNav: true,
            },
            component: () => import('@/views/examManage/examIndex.vue'),
        }
    ]
}

export default examBigData