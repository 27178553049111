// 考题模块

import LayoutMain from '@/layout/LayoutMain'

const examQuestions = {
    path: '/examQuestions',
    component: LayoutMain,
    redirect: '/examQuestions/examQuestionsManage',
    meta: {
        title: '考题管理',
        LoginRoad: '市级题库管理',
        icon: 'el-icon-s-order'
    },
    children: [
        {
            path: '/examQuestions/examQuestionsManage',
            name: 'examQuestionsManage',
            meta: {
                title: '考题配置',
                LoginRoad: '考题配置',
            },
            component: () => import('@/views/examQuestions/examQuestionsManage.vue'),
        },
        {
            path: '/examQuestions/examQuestionsEdit',
            name: 'examQuestionsManage',
            meta: {
                title: '考题编辑',
                hideNav: true
            },
            component: () => import('@/views/examQuestions/examQuestionsEdit.vue'),
        },
        {
            path: '/examQuestions/examQuestionsView',
            name: 'examQuestionsManage',
            meta: {
                title: '考题查看',
                hideNav: true
            },
            component: () => import('@/views/examQuestions/examQuestionsView.vue'),
        }
    ]
}

export default examQuestions