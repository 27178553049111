import { request, noTimeOutReq } from "@/utils/request.js";

//条件查询考试成绩列表
export function queryScoreList(pageIndex, pageSize, data) {
  return request({
    url: `/exam/achievement/summary/list/${pageIndex}/${pageSize}`,
    method: "post",
    data,
  });
}

//查看成绩汇总信息
export function queryCollect(examId, data) {
  return request({
    url: `/exam/achievement/summary/get/collect/${examId}`,
    method: "post",
    data,
  });
}

//查询考试信息
export function queryExamInfo(examId) {
  return request({
    url: `/exam/achievement/summary/get/examInfo/${examId}`,
    method: "post",
  });
}

//查询历史平均分
export function queryAverageScore(examId, data) {
  return request({
    url: `/exam/achievement/summary/get/examHistory/${examId}`,
    method: "post",
    data,
  });
}

//查询考生考试排行榜
export function queryStudentRank(examId, pageIndex, pageSize, data) {
  return request({
    url: `/exam/achievement/summary/list/examineeRanking/${examId}/${pageIndex}/${pageSize}`,
    method: "post",
    data,
  });
}

//查询学校考试排行榜
export function querySchoolRank(examId, pageIndex, pageSize, data) {
  return request({
    url: `/exam/achievement/summary/list/schoolRinking/${examId}/${pageIndex}/${pageSize}`,
    method: "post",
    data,
  });
}

// 查询排行榜过滤条件
export function queryRankFilterConditionApi(examId) {
  return request({
    url: `/exam/achievement/summary/get/examineeRanking/condition/${examId}`,
    method: "post",
  });
}

// 下载考试排行榜
export function downloadPankDataApi(examId, data) {
  return request({
    url: `/exam/achievement/summary/poi/sheet/ranking/download/${examId}`,
    method: "post",
    data,
  });
}

//查询历史平均分
export function queryHistory(examId, data) {
  return request({
    url: `/exam/achievement/summary/get/examHistory/${examId}`,
    method: "post",
    data,
  });
}

export function queryCondition(examId) {
  return request({
    url: `/exam/achievement/summary/get/view/condition/${examId}`,
    method: "post",
  });
}

export function queryAchievementDetailCondition(examId) {
  return request({
    url: `/exam/achievement/summary/get/condition/${examId}`,
    method: "post",
  });
}

export function queryAchievementDetail(pageIndex, pageSize, examId, data) {
  return request({
    url: `/exam/achievement/summary/list/${examId}/${pageIndex}/${pageSize}`,
    method: "post",
    data,
  });
}

export function downloadAchievementUrl(examId, data) {
  return request({
    url: `/exam/achievement/summary/poi/sheet/download/${examId}`,
    method: "post",
    data,
  });
}

export function getExamAchievementSheetFilterCondition(examId) {
  return request({
    url: `/exam/achievement/summary/sheet/get/condition/${examId}`,
    method: "post",
  });
}
//提出异议
export function saveConfusionApi(data) {
  return request({
    url: `/exam/score/achievement/confusion/save`,
    method: "post",
    data: data,
  });
}

export function checkConfusionApi(data) {
  return request({
    url: `/exam/score/achievement/confusion/check`,
    method: "post",
    data: data,
  });
}

export function querySheetAchievementDetail(pageIndex, pageSize, examId, data) {
  return request({
    url: `/exam/achievement/summary/list/sheet/${examId}/${pageIndex}/${pageSize}`,
    method: "post",
    data,
  });
}

// 成绩分析详情
export function getScoreAnalysisDetailApi(examId, data) {
  return request({
    url: `/exam/achievement/summary/list/analysis/test/${examId}`,
    method: "post",
    data,
  });
}

// 考题分析总览
export function getScoreAnalysisSummaryApi(examId, data) {
  return request({
    url: `/exam/achievement/summary/list/analysis/test/overview/${examId}`,
    method: "post",
    data,
  });
}

// 下载考题分析【excel】
export function downloadScoreAnalysisSummaryApi(examId) {
  return request({
    url: `/exam/achievement/summary/download/analysis/test/overview/${examId}`,
    method: "post"
  });
}