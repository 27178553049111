<template>
  <div class="picLayer" ref="picLayer" v-drag>
    <div class="testClass">
      <span id="pdclose" @click="closeLayer"></span>
      <el-button
        v-throttle
        icon="el-icon-arrow-left"
        circle
        id="leftBtn"
        @click="goLeft"
      ></el-button>
      <el-button
        v-throttle
        icon="el-icon-arrow-right"
        circle
        id="rightBtn"
        @click="goRight"
      ></el-button>
    </div>
    <div class="imgList" v-for="(v, i) in picUrl" :key="i">
      <img :src="picUrl[i]" class="itemDe" alt />
    </div>
  </div>
</template>

<script>
export default {
  props: ['picUrl'],
  data() {
    return {
      index: 0,
      picList: [],
    }
  },

  mounted() {
    this.goIndex()
  },

  methods: {
    goIndex() {
      var items = document.querySelectorAll('.itemDe') //图片节点
      console.log(items, 'items')
      for (var i = 0; i < items.length; i++) {
        items[i].className = 'itemDe'
      }
      items[this.index].className = 'itemDe active'
    },

    goLeft() {
      var items = document.querySelectorAll('.itemDe') //图片节点
      console.log(this.index, 'index')
      console.log(items.length)
      if (this.index == 0) {
        this.index = items.length - 1
      } else {
        this.index--
      }
      this.goIndex()
    },

    goRight() {
      console.log(this.index, 'index')
      var items = document.querySelectorAll('.itemDe') //图片节点
      if (this.index < items.length - 1) {
        this.index++
      } else {
        this.index = 0
      }
      this.goIndex()
    },

    //关闭弹窗
    closeLayer() {
      this.$emit('closeState', true)
    },
  },

  directives: {
    drag(el) {
      let oDiv = el // 当前元素
      // let self = this // 上下文
      // 禁止选择网页上的文字
      document.onselectstart = function () {
        return false
      }
      oDiv.onmousedown = function (e) {
        // 鼠标按下，计算当前元素距离可视区的距离
        let disX = e.clientX - oDiv.offsetLeft
        let disY = e.clientY - oDiv.offsetTop
        document.onmousemove = function (e) {
          // 通过事件委托，计算移动的距离
          let l = e.clientX - disX
          let t = e.clientY - disY
          // 移动当前元素
          oDiv.style.left = l + 'px'
          oDiv.style.top = t + 'px'
        }
        document.onmouseup = function (e) {
          document.onmousemove = null
          document.onmouseup = null
        }
        // return false不加的话可能导致黏连，就是拖到一个地方时div粘在鼠标上不下来，相当于onmouseup失效
        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.picLayer {
  left: 600px;
  top: 400px;
  border: 2px solid #409eff;
  width: 800px;
  height: 480px;
  overflow: hidden;
  position: absolute;
  z-index: 999;
  overflow-x: auto;
  overflow-y: auto;
  // resize: both;
}
.testClass {
  position: relative;
  width: 100%;
  height: 100%;
}
.picLayer .itemDe {
  width: 100%;
  position: absolute;
  left: 0;
  opacity: 0;
}
.imgList {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow: auto;
  top: 0;
  left: 0;
}
.imgList .active {
  z-index: 100;
  opacity: 1;
}

.el-button {
  margin-top: 28%;
  position: absolute;
  z-index: 1000;
}

#leftBtn {
  margin-left: 0;
}

#rightBtn {
  right: 10px;
}

// 滚动条样式
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

// ::-webkit-scrollbar-button {
//   background-color: red;
// }

::-webkit-scrollbar-track-piece {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

//关闭按钮
#pdclose {
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  // overflow: hidden;
  margin-top: 10px;
  right: 15px;
  z-index: 1000;
}

#pdclose:after {
  width: 100%;
  position: absolute;
  height: 1.5px;
  background: #000000;
  content: '';
  top: 9px;
  left: 0;
  transform: rotate(134deg);
  -ms-transform: rotate(134deg);
  -moz-transform: rotate(134deg);
  -webkit-transform: rotate(134deg);
  -o-transform: rotate(134deg);
}

#pdclose:before {
  width: 100%;
  position: absolute;
  height: 1.5px;
  background: #000000;
  content: '';
  top: 9px;
  right: 0;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
</style>
