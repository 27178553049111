const examResourceOccupation = {
  state: {
    //时长
    duration: 0,
    subjectNum: 1,
    venueNum: 0,
    seatNum: 0,
    // 考试占用
    exam: 0,
    // 考场占用
    venue: 0,
    // 学生机占用
    machine: 0,
    // 考生总数
    people: 0,
  },
  mutations: {
    set_duration (state, val) {
      state.duration = val;
      this.dispatch('compute_resourceOccupation');
    },
    // set_subjectNum (state, val) {
    //   state.subjectNum = val;
    //   this.dispatch('compute_resourceOccupation');
    // },
    set_seatNum (state, val) {
      state.seatNum = val;
      this.dispatch('compute_resourceOccupation');
    },
    set_venueNum (state, val) {
      state.venueNum = val;
      this.dispatch('compute_resourceOccupation');
    },
    set_people (state, val) {
      state.people = val.param1 / val.param2;
      state.subjectNum = val.param2;
      this.dispatch('compute_resourceOccupation');
    },
    update_resourceOccupation (state, { exam, venue, machine }) {
      state.exam = exam;
      state.venue = venue;
      state.machine = machine;
    },
  },
  actions: {
    compute_resourceOccupation ({ commit, state }) {
      const AUDIO_BITRATE = window.server.AUDIO_BITRATE;
      const VIDEO_BITRATE = window.server.VIDEO_BITRATE;
      const VIDEO_NUM = window.server.VIDEO_NUM;
      const videoSize = (AUDIO_BITRATE + VIDEO_BITRATE) * state.duration * 60 / 8 / 1024 * VIDEO_NUM;
      const exam = (state.people * videoSize * state.subjectNum / 1024).toFixed(2);

      if (state.venueNum <= 0) {
        console.error("考场数量不能为0");
        commit('set_venueNum', 1);
      }

      const venue = (exam / state.venueNum).toFixed(2);
      // state.seatNum = Math.floor(state.people / state.venueNum);

      if (state.seatNum <= 0) {
        console.error("座位数量不能为0");
        commit('set_seatNum', 1);
      }

      const machine = (venue / state.seatNum).toFixed(2);

      commit('update_resourceOccupation', { exam, venue, machine });
    },
  }

}

export default examResourceOccupation