// 厂商对接
import LayoutMain from '@/layout/LayoutMain'

import { getLoginRoad } from "@/utils/token.js";


const manufacturerDocking = {
    path: '/docking',
    component: LayoutMain,
    redirect: '/docking/manufacturerDocking',
    meta: {
        title: '上级平台',
        LoginRoad: '厂商对接管理',
        icon: 'el-icon-s-promotion'

    },
    children: [
        {
            path: '/docking/manufacturerDocking',
            name: 'manufacturerDocking',
            meta: {
                title: '厂商对接',
                LoginRoad: '厂商对接',
                hideNav: !getLoginRoad(),
            },
            component: () => import('@/views/manufacturerDocking/manufacturerDocking.vue'),
        },
        {
            path: '/docking/manufacturerDocking',
            name: 'manufacturerDocking',
            meta: {
                title: '对接管理',
                hideNav: !!getLoginRoad(),
            },
            component: () => import('@/views/examManage/examIndex.vue'),
        }
    ]
}

export default manufacturerDocking