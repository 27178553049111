// 【auth】用户注册、认证、授权

import { request, noTimeOutReq } from '@/utils/request.js'
import store from '@/store'

// 获取版本号
export function getVersion () {
    return request({
        url: '/server/web/version/release/get',
        method: 'post'
    })
}

// 注册
export function register (data) {
    return request({
        url: '/auth/user/register/add',
        method: 'post',
        data
    })
}
// 登录
export function login (data) {
    return request({
        url: '/auth/user/login/get',
        method: 'post',
        data
    })
}

// 退出
export function quit () {
    store.commit('EMPTY_NAV')
    return request({
        url: '/auth/user/logout/delete',
        method: 'post',
    })
}

//获取服务器配置
export function getServerConfig () {
    return request({
        url: '/server/web/config/platform/local/list',
        method: 'post',
    })
}


// 获取用户所有角色 路由
export function getRole () {
    return request({
        url: '/user/auth/user/role/get',
        method: 'post',
    })
}

// V3.8.x升级V4.2.x变更
export function updateVersion () {
    return request({
        url: '/admin/server/update/three/to/four',
        method: 'post',
    })
}

// 验证用户身份
export function verifyLogin (data) {
    return request({
        url: '/auth/user/verify/get',
        method: 'post',
        data
    })
}

// 验证用户身份
export function deleteSysExamData (data) {
    return request({
        url: '/admin/server/data/exam/sys/delete',
        method: 'post',
        data
    })
}