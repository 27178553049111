// 机构模快

import LayoutMain from '@/layout/LayoutMain'

const mechanism = {
    path: '/mechanism',
    component: LayoutMain,
    redirect: '/mechanism/mechanismManage',
    meta: {
        title: '考点管理',
        LoginRoad: '市级考点管理',
        icon: 'el-icon-s-tools'
    },
    children: [
        {
            path: '/mechanism/mechanismManage',
            name: 'mechanismManage',
            meta: {
                title: '区县管理',
                LoginRoad: '区县管理',
            },
            component: () => import('@/views/mechanism/mechanismManage/mechanismManage.vue'),
        },
        {
            path: '/mechanism/areaManage',
            name: 'areaManage',
            meta: {
                title: '学校管理',
                LoginRoad: '学校管理',
            },
            component: () => import('@/views/mechanism/schoolManage/schoolManage.vue'),
        },
        {
            path: '/mechanism/examPlaceManage',
            name: 'examPlaceManage',
            meta: {
                title: '考点管理',
                LoginRoad: '考点管理',
            },
            component: () => import('@/views/mechanism/examPlaceManage/examPlaceManage.vue'),
        },
        {
            path: '/mechanism/editExamPlaceManage',
            name: 'editExamPlaceManage',
            meta: {
                title: '考场编辑',
                LoginRoad: '考场编辑',
                hideNav:true
            },
            component: () => import('@/views/mechanism/examPlaceManage/editExamPlaceManage.vue'),
        },
        {
            path: '/mechanism/functionRoom',
            name: 'functionRoom',
            meta: {
                title: '功能室编辑',
                LoginRoad: '功能室编辑',
                hideNav:true
            },
            component: () => import('@/views/mechanism/examPlaceManage/functionRoom.vue'),
        },
    ]
}

export default mechanism