// 评卷模块

import LayoutMain from '@/layout/LayoutMain'

const marking = {
  path: '/marking',
  component: LayoutMain,
  redirect: '/marking/markingManage',
  meta: {
    title: '阅卷管理',
    LoginRoad: '市级阅卷管理',
    icon: 'el-icon-s-tools',
  },
  children: [{
    path: '/marking/markingManage',
    name: 'markingManage',
    meta: {
      title: '阅卷设置',
      LoginRoad: '阅卷设置',
      // keepAlive: true,
    },
    component: () => import('@/views/marking/markingManage.vue'),
  },
  {
    path: '/marking/markingPlainManage',
    name: 'markingPlainManage',
    meta: {
      title: '阅卷入口',
      LoginRoad: '阅卷入口',
    },
    component: () => import('@/views/marking/markingPlainManage.vue'),
  },
  {
    path: '/marking/abnormalDispose',
    name: 'abnormalDispose',
    meta: {
      title: '异常处理',
      LoginRoad: '异常处理',
      // hideNav: true,
    },
    component: () => import('@/views/marking/abnormalDispose.vue'),
  },
  {
    path: '/marking/abnormalDialog',
    name: 'abnormalDialog',
    meta: {
      title: '异常处理详情',
      LoginRoad: '异常处理详情',
      hideNav: true,
    },
    component: () => import('@/views/marking/abnormalDialog.vue'),
  },
  {
    path: '/marking/picHandleDetail',
    name: 'picHandleDetail',
    meta: {
      title: '图片处理详情',
      LoginRoad: '图片处理详情',
      hideNav: true,
    },
    component: () => import('@/views/marking/picHandleDetail.vue'),
  },
  {
    path: '/marking/markingDetails',
    name: 'markingDetails',
    meta: {
      title: '开始评卷',
      hideNav: true
    },
    component: () => import('@/views/marking/markingDetails.vue'),
  },
  {
    path: '/marking/markingTest',
    name: 'markingTest',
    meta: {
      title: '试评卷',
      hideNav: true
    },
    component: () => import('@/views/marking/markingTest.vue'),
  },
  {
    path: '/marking/markingSetting',
    name: 'markingSetting',
    meta: {
      title: '设置评卷规则',
      hideNav: true
    },
    component: () => import('@/views/marking/markingSetting.vue'),
  },
  {
    path: '/marking/markingSettingRevise',
    name: 'markingSettingRevise',
    meta: {
      title: '设置评卷规则',
      hideNav: true
    },
    component: () => import('@/views/marking/markingSettingRevise.vue'),
  },
  {
    path: '/marking/markingClient/clientIndex',
    name: 'clientIndex',
    meta: {
      title: '评卷客户端管理',
      hideNav: true
    },
    component: () => import('@/views/marking/markingClient/clientIndex.vue'),
  },
  {
    path: '/marking/markingClient/clientManage',
    name: 'clientManage',
    meta: {
      title: '评卷管理座位列表',
      hideNav: true
    },
    component: () => import('@/views/marking/markingClient/clientManage.vue'),
  },
  {
    path: '/marking/markingClient/clientCache',
    name: 'clientCache',
    meta: {
      title: '评卷管理缓存列表',
      hideNav: true
    },
    component: () => import('@/views/marking/markingClient/clientCache.vue'),
  },
  {
    path: '/marking/markingTest/testPaperSetting',
    name: 'testPaperSetting',
    meta: {
      title: '试评卷设置',
      hideNav: true
    },
    component: () => import('@/views/marking/testPaperSetting.vue'),
  }
  ],
}

export default marking