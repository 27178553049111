// 通知模块

import LayoutMain from '@/layout/LayoutMain'

const notice = {
    path: '/mag',
    component: LayoutMain,
    redirect: '/mag/notice',
    meta: {
        title: '通知模块',
        icon: 'el-icon-message-solid',
        // 临时
        hideNav: true,
    },
    children: [
        {
            path: '/mag/notice',
            name: 'notice',
            meta: {
                title: '通知模块',
                // hideNav: true
                // 临时
                hideNav: true,
            },
            component: () => import('@/views/examManage/examIndex.vue'),
        }
    ]
}

export default notice