// 接口开发

import LayoutMain from '@/layout/LayoutMain'

const interfaceDevelopment = {
    path: '/development',
    component: LayoutMain,
    redirect: '/development/interfaceDevelopment',
    meta: {
        title: '接口开发',
        icon: 'el-icon-s-help',
        // 临时
        hideNav: true,
    },
    children: [
        {
            path: '/development/interfaceDevelopment',
            name: 'interfaceDevelopment',
            meta: {
                title: '接口开发',
                // 临时
                hideNav: true,
            },
            component: () => import('@/views/examManage/examIndex.vue'),
        }
    ]
}

export default interfaceDevelopment