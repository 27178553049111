import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { getToken, setToken, getLoginRoad } from '@/utils/token.js'
import LayoutMain from '@/layout/LayoutMain'

import allRouter from '@/router/modules/allRouter.js'

import { getServerConfig } from '@/api/auth.js'
import { getStore, setStore } from '@/utils/token.js'
import { isEmpty } from '@/utils/util.js'

import { queryScoreList } from "@/api/score/scoreQuery.js";

Vue.use(VueRouter)
// 静态路由
const routes = [
  // {
  //   path: '/',
  //   name: 'LayoutMain',
  //   component: LayoutMain,
  //   children: [
  //     // 嵌套路由
  //   ]
  // },
  {
    path: '/',
    component: LayoutMain,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        meta: {
          title: '首页',

          icon: 'el-icon-s-platform',
        },
        component: () => import('@/views/home/home.vue'),
      },
    ],
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      illustrate: '注册',
    },
    component: () => import('@/views/register/register.vue'),
  },
  {
    path: '/manageLogin',
    name: 'manageLogin',
    meta: {
      title: "考务管理",
      illustrate: '考务管理登录',
    },
    component: () => import('@/views/Login/manageLogin.vue'),
  },
  {
    path: '/scoreLogin',
    name: 'scoreLogin',
    meta: {
      title: "现考现评",
      illustrate: '现考现评登录',
    },
    component: () => import('@/views/Login/scoreLogin.vue'),
  },
  {
    path: '/checkInLogin',
    name: 'checkInLogin',
    meta: {
      title: "现场报到",
      illustrate: '现场报到登录',
    },
    component: () => import('@/views/Login/checkInLogin.vue'),
  },
  {
    path: '/drawLotsLogin',
    name: 'drawLotsLogin',
    meta: {
      title: "现场抽签",
      illustrate: '现场抽签登录',
    },
    component: () => import('@/views/Login/drawLotsLogin.vue'),
  },
  {
    path: '/examAfterScore',
    name: 'examAfterScore',
    meta: {
      title: "现考后评",
      illustrate: '现考后评登录',
    },
    component: () => import('@/views/Login/examAfterScore.vue'),
  },
  {
    path: '/selectLogin',
    name: 'selectLogin',
    meta: {
      title: "实验考试系统",
      illustrate: '选择登录',
    },
    component: () => import('@/views/Login/selectLogin.vue'),
  },
  {
    path: '/cityPlatformLogin',
    name: 'cityPlatformLogin',
    meta: {
      title: "平台登录",
      illustrate: '市级平台登录',
    },
    component: () => import('@/views/Login/cityPlatformLogin.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

// 路由导航守卫
router.beforeEach((to, from, next) => {
  //设置服务器配置
  let local_oss_store = getStore('LOCAL_OSS');
  let local_media_store = getStore('LOCAL_MEDIA');
  let local_media_http_store = getStore('LOCAL_MEDIA_HTTP');
  let local_oss = null;
  let local_media = null;
  let local_media_http = null;
  getServerConfig().then((res) => {
    if (res.success) {
      res.data.forEach(item => {
        switch (item.type) {
          case 4:
            local_oss = item;
            setStore('LOCAL_OSS', `${local_oss.protocol}://${local_oss.ip}:${local_oss.port}`);
            break;
          case 8:
            local_media = item;
            setStore('LOCAL_MEDIA', `${local_media.protocol}://${local_media.ip}:${local_media.port}`);
            break;
          case 9:
            local_media_http = item;
            setStore('LOCAL_MEDIA_HTTP', `${local_media_http.protocol}://${local_media_http.ip}:${local_media_http.port}`);
            break;
        }
        // if (item.code == 'local_front') {
        //   if (getStore('PLAT_FOR_NAME') != item.platformName) {
        //     setStore('PLAT_FOR_NAME', item.platformName)
        //   }
        // }
      })
    }
  })
  // 动态title
  if (to.meta.title) {
    document.title = to.meta.title
  }

  const token = getToken()

  // 所有的登录页面 (及开发所用注册页)
  const notLogged = [
    '/selectLogin',
    '/drawLotsLogin',
    '/checkInLogin',
    '/scoreLogin',
    '/manageLogin',
    '/register',
    '/examAfterScore',
    '/cityPlatformLogin'
  ]
  if (token) {
    // 已登录
    // console.log('已登录')
    if (notLogged.indexOf(to.path) != -1) next({ path: '/' })
      ; (!!store.getters.nav.length) && store.commit('SET_NAV', allRouter)
    if (store.getters.nav.length) {
      if (to.name == 'home') {
        // 判断市级还是校级进行首页跳转
        if (!getLoginRoad()) {
          queryScoreList(1, 1, {}).then(res => {
            if (res.success) {
              if (res.data.length !== 0) {
                next({
                  name: 'scoreInfo', query: { examId: res.data[0].examId, subject: res.data[0].subjectTypeList }
                })
              } else {
                next({ path: `/exam/examManage` })
              }
            } else {
              next({ path: `/exam/examManage` })
            }
          }).catch(err => {
            next({ path: `/exam/examManage` })
          })
        } else {
          next()
        }
      } else {
        next()
      }

    } else {
      router.addRoutes(allRouter)
      store.commit('SET_NAV', allRouter)
      // store.dispatch('SET_NAV', allRouter)
      next({ ...to, replace: true })
    }
  } else {
    console.log('未登录')
    console.log(to.name)
    // 未登录时 只能转跳至登录或注册页面

    notLogged.indexOf(to.path) != -1 ? next() : next({ path: '/selectLogin' })

  }

})


export default router
