// import home from "./home"
// import examManage from "./examManage"

import user from './user'
import mechanism from './mechanism'
import examQuestions from './examQuestions'
import exam from './exam'
import score from './score'
import archive from './archive'
import manufacturerDocking from './manufacturerDocking'
import monitor from './monitor'
import notice from './notice'
import interfaceDevelopment from './interfaceDevelopment'
import examBigData from './examBigData'
import systemManagement from './systemManagement'
import marking from './marking'
import rollCall from './rollCall'
import PadRating from './PadRating'
import markingVideo from './markingVideo'
import draw from './draw'
import account from './account'
import schoolManufacturer from './schoolManufacturer'
import scoreAI from './scoreAI'

import LayoutMain from '@/layout/LayoutMain'

const allRouter = [
  user,
  mechanism,
  examQuestions,
  exam,
  marking,
  scoreAI,
  score,
  archive,
  // manufacturerDocking,
  monitor,
  notice,
  interfaceDevelopment,
  examBigData,
  schoolManufacturer,
  systemManagement,
  account,

  ...rollCall,
  ...PadRating,
  ...markingVideo,
  ...draw
]

export default allRouter