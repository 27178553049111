// 用户模块
import LayoutMain from '@/layout/LayoutMain'

const schoolManufacturer = {
  path: '/schoolManufacturer',
  component: LayoutMain,
  redirect: '/schoolManufacturer/schoolManufacturerManage',
  meta: {
    title: '上级平台管理',
    LoginRoad: '',
    icon: 'el-icon-s-promotion',
  },
  children: [
    {
      path: '/schoolManufacturer/baseData',
      name: 'baseData',
      meta: {
        title: '基础数据',
        // LoginRoad: '教师管理',
        cacheKeep: true
      },
      component: () => import('@/views/schoolManufacturer/baseData.vue'),
    },
    {
      path: '/schoolManufacturer/examManage',
      name: 'examManage',
      meta: {
        title: '考试管理',
        // LoginRoad: '教师管理',
      },
      component: () => import('@/views/schoolManufacturer/examManage.vue'),
    },
    {
      path: '/schoolManufacturer/examInfo',
      name: 'examInfo',
      meta: {
        title: '考试信息',
        // LoginRoad: '教师管理',
        hideNav: true
      },
      component: () => import('@/views/schoolManufacturer/examInfo.vue'),
    },
    {
      path: '/schoolManufacturer/afterExamination',
      name: 'afterExamination',
      meta: {
        title: '考后上传(考后评)',
        // LoginRoad: '教师管理',
        hideNav: true
      },
      component: () => import('@/views/schoolManufacturer/afterExamination.vue'),
    },
    {
      path: '/schoolManufacturer/middleExamination',
      name: 'middleExamination',
      meta: {
        title: '考后上传(考中评)',
        // LoginRoad: '教师管理',
        hideNav: true
      },
      component: () => import('@/views/schoolManufacturer/middleExamination.vue'),
    },
    {
      path: '/schoolManufacturer/exceptionReport',
      name: 'exceptionReport',
      meta: {
        title: '异常上报',
        // LoginRoad: '教师管理',
      },
      component: () => import('@/views/schoolManufacturer/exceptionReport.vue'),
    },
    {
      path: '/schoolManufacturer/placeData',
      name: 'baseData',
      meta: {
        title: '考点数据',
        // LoginRoad: '教师管理',
        hideNav: true
      },
      component: () => import('@/views/schoolManufacturer/component/placeData.vue'),
    },
    {
      path: '/schoolManufacturer/schoolData',
      name: 'baseData',
      meta: {
        title: '学校数据',
        // LoginRoad: '教师管理',
        hideNav: true
      },
      component: () => import('@/views/schoolManufacturer/component/schoolData.vue'),
    },
    {
      path: '/schoolManufacturer/examineeData',
      name: 'baseData',
      meta: {
        title: '学生数据',
        // LoginRoad: '教师管理',
        hideNav: true
      },
      component: () => import('@/views/schoolManufacturer/component/examineeData.vue'),
    },
    {
      path: '/schoolManufacturer/teacherData',
      name: 'baseData',
      meta: {
        title: '老师数据',
        // LoginRoad: '教师管理',
        hideNav: true
      },
      component: () => import('@/views/schoolManufacturer/component/teacherData.vue'),
    },
    {
      path: '/schoolManufacturer/questionData',
      name: 'baseData',
      meta: {
        title: '考题数据',
        // LoginRoad: '教师管理',
        hideNav: true,
      },
      component: () => import('@/views/schoolManufacturer/component/questionData.vue'),
    },
    {
      path: '/schoolManufacturer/placeInfo',
      name: 'placeInfo',
      meta: {
        title: '查看考场',
        // LoginRoad: '教师管理',
        hideNav: true,
      },
      component: () => import('@/views/schoolManufacturer/component/placeInfo.vue'),
    },
    {
      path: '/schoolManufacturer/questionInfo',
      name: 'questionInfo',
      meta: {
        title: '查看考题',
        // LoginRoad: '教师管理',
        hideNav: true,
      },
      component: () => import('@/views/schoolManufacturer/component/questionInfo.vue'),
    },
  ],
}

export default schoolManufacturer