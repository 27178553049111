// 成绩模块

import LayoutMain from '@/layout/LayoutMain'

const score = {
  path: '/scoreAI',
  component: LayoutMain,
  redirect: '/scoreAI/scoring',
  meta: {
    title: 'AI评分管理',
    LoginRoad: 'AI评分管理',
    icon: 'el-icon-finished',
  },
  children: [
    {
      path: '/scoreAI/AIMarkingManage',
      name: 'AIMarkingManage',
      meta: {
        title: 'AI评分',
        LoginRoad: 'AI评分',
      },
      component: () => import('@/views/scoreAI/AIMarkingManage'),
    },
    {
      path: '/scoreAI/AIConcentratedScore',
      name: 'AIConcentratedScore',
      meta: {
        title: 'AI集中评',
        LoginRoad: 'AI集中评',
        hideNav: true,
      },
      component: () => import('@/views/scoreAI/AIConcentratedScore'),
    },
    {
      path: '/scoreAI/AIConcentratedScoreVideo',
      name: 'AIConcentratedScoreVideo',
      meta: {
        title: 'AI评分结果',
        LoginRoad: 'AI评分结果',
        hideNav: true,
      },
      component: () => import('@/views/scoreAI/AIConcentratedScoreVideo'),
    },
    {
    path: '/scoreAI/scoring',
    name: 'scoring',
    meta: {
      title: '正在评分',
      LoginRoad: '正在评分',
    },
    component: () => import('@/views/scoreAI/scoring'),
  },
  {
    path: '/scoreAI/detailScoring',
    name: 'detailScoring',
    meta: {
      title: '正在评分详情',
      LoginRoad: '正在评分详情',

      hideNav: true,
    },
    component: () => import('@/views/scoreAI/detailScoring'),
  },
  {
    path: '/scoreAI/detailHistoryScore',
    name: 'detailHistoryScore',
    meta: {
      title: '历史评分详情',
      LoginRoad: '历史评分详情',

      hideNav: true,
    },
    component: () => import('@/views/scoreAI/detailHistoryScore'),
  },
  {
    path: '/scoreAI/historyScore',
    name: 'historyScore',
    meta: {
      title: '历史评分',
      LoginRoad: '历史评分',
    },
    component: () => import('@/views/scoreAI/historyScore'),
  },
  {
    path: '/scoreAI/resultConfidence',
    name: 'resultConfidence',
    meta: {
      title: '置信卷',
      LoginRoad: '置信卷',
    },
    component: () => import('@/views/scoreAI/resultConfidence'),
  },
  {
    path: '/scoreAI/notResultConfidence',
    name: 'notResultConfidence',
    meta: {
      title: '非置信卷',
      LoginRoad: '非置信卷',
    },
    component: () => import('@/views/scoreAI/notResultConfidence'),
  },
  {
    path: '/scoreAI/exportHistoryScore',
    name: 'exportHistoryScore',
    meta: {
      title: '结果导出',
      LoginRoad: '结果导出',
    },
    component: () => import('@/views/scoreAI/exportHistoryScore'),
  },
  {
    path: '/scoreAI/experimentManagement',
    name: 'experimentManagement',
    meta: {
      title: '实验管理',
      LoginRoad: '实验管理',
    },
    component: () => import('@/views/scoreAI/experimentManagement'),
  },
  {
    path: '/scoreAI/scoreDisposition',
    name: 'scoreDisposition',
    meta: {
      title: '评分配置',
      LoginRoad: '评分配置',
      hideNav: true,
    },
    component: () => import('@/views/scoreAI/scoreDisposition'),
  },
  {
    path: '/scoreAI/AITeachExamsManage',
    name: 'AITeachExamsManage',
    meta: {
      title: 'AI教考练管理',
      LoginRoad: 'AI教考练管理',
    },
    component: () => import('@/views/scoreAI/AITeachExamsManage'),
  },
  {
    path: '/scoreAI/editingStepExperimentManagement',
    name: 'editingStepExperimentManagement',
    meta: {
      title: '步骤编辑',
      LoginRoad: '步骤编辑',
      hideNav: true,
    },
    component: () => import('@/views/scoreAI/editingStepExperimentManagement'),
  },
  {
    path: '/scoreAI/hashPowerBox',
    name: 'hashPowerBox',
    meta: {
      title: '算力盒管理',
      LoginRoad: '算力盒管理',
    },
    component: () => import('@/views/scoreAI/hashPowerBox'),
  },
  ],
}

export default score