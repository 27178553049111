// 成绩模块

import LayoutMain from '@/layout/LayoutMain'

const score = {
  path: '/score',
  component: LayoutMain,
  redirect: '/examManage/examIndex',
  meta: {
    title: '成绩管理',
    LoginRoad: '市级成绩管理',
    icon: 'el-icon-s-claim',
  },
  children: [{
      path: '/examManage/examIndex',
      name: 'scoreInquire',
      meta: {
        title: '成绩查询',
        LoginRoad: '成绩查询',
      },
      component: () => import('@/views/examManage/examIndex.vue'),
    },
    {
      path: '/examManage/examDetails',
      name: 'scoreInfo',
      meta: {
        title: '查看成绩',
        hideNav: true,
      },
      component: () => import('@/views/examManage/examDetails.vue'),
    },
    {
      path: '/examManage/examDetailed',
      name: 'scoreDetailed',
      meta: {
        title: '成绩明细',
        hideNav: true,
      },
      component: () => import('@/views/examManage/examDetailed.vue'),
    },
    {
      path: '/examManage/examObjection',
      name: 'objection',
      meta: {
        title: '成绩异议',
        hideNav: true,
      },
      component: () => import('@/views/examManage/examObjection.vue'),
    },
    {
      path: '/examManage/scoreDetails',
      name: 'scoreDetails',
      meta: {
        title: '成绩明细详情页',
        hideNav: true,
      },
      component: () => import('@/views/examManage/scoreDetails.vue'),
    },
    {
      path: '/examManage/examAnalyze',
      name: 'analyze',
      meta: {
        title: '纵向分析',
        hideNav: true,
      },
      component: () => import('@/views/examManage/examAnalyze.vue'),
    },
    {
      path: '/examManage/examObjectionView',
      name: 'examObjectionView',
      meta: {
        title: '查看我的异议',
        hideNav: true,
      },
      component: () => import('@/views/examManage/examObjectionView.vue'),
    },
    {
      path: '/examManage/examObjectionMarking',
      name: 'examObjectionMarking',
      meta: {
        title: '异议重新阅卷',
        hideNav: true,
      },
      component: () => import('@/views/examManage/examObjectionMarking.vue'),
    },
    {
      path: '/examManage/peopleCounting',
      name: 'peopleCounting',
      meta: {
        title: '分段人数',
        hideNav: true,
      },
      component: () => import('@/views/examManage/peopleCounting.vue'),
    },
    {
      path: '/examManage/scoreCounting',
      name: 'scoreCounting',
      meta: {
        title: '分段分数',
        hideNav: true,
      },
      component: () => import('@/views/examManage/scoreCounting.vue'),
    }, 
    {
      path:'/examManage/scoreChartDetail',
      name:'scoreChartDetail',
      meta: {
        title:'排行榜',
        hideNav: true,
      },
      component: () => import('@/views/examManage/scoreChartDetail.vue'),
    },
    {
      path:'/examManage/analysisDetail',
      name:'analysisDetail',
      meta: {
        title:'试题分析',
        hideNav: true,
      },
      component: () => import('@/views/examManage/analysisDetail.vue'),
    }
  ],
}

export default score